import { useState, useEffect } from 'react';
import './experience.css'
import NumberedHeader from '../ReusableComponents/numberedHeader';

function Experience() {
    const [title, setTitle] = useState('');
    const [timeLine, setTimeLine] = useState('');
    const [content, setContent] = useState(['']);
    const [company, setCompany] = useState('');
    const [url, setUrl] = useState('');
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);

    const handleClick = (newTitle: string, newTimeLine : string, newContent : string[], newCompany : string, newUrl : string, index: number) => {
      setTitle(newTitle);
      setTimeLine(newTimeLine);
      setContent(newContent);
      setCompany(newCompany);
      setUrl(newUrl);
      setSelectedButtonIndex(index);
    };

    useEffect(() => {
        const defaultButton = buttons[0];
        setTitle(defaultButton.title);
        setTimeLine(defaultButton.timeLine);
        setContent(defaultButton.content);
        setCompany(defaultButton.company);
        setUrl(defaultButton.url);
      }, []);
      
    const buttons = [
        { label: 'Button 1', title: 'Junior Software Developer'  , timeLine:'Feb 2022 - Present', content: ['Writing new, effective code that is easy to maintain for a variety internal projects','Working with a variety of different languages, platforms, frameworks such as TypeScript, C#, SQL','Normalization of data'], company: 'Energi Danmark', url: 'https://www.energidanmark.dk/' },
        { label: 'Button 2', title: 'Software Developer Intern' , timeLine:'Oct 2021 - Jan 2022', content: ['Architected and implemented the front-end project using Flutter'], company: 'Anzaro', url: 'https://www.anzaro.dk' },
        { label: 'Button 3', title: 'Software Developer Intern' , timeLine:'Aug 2021 - Oct 2021', content: ['Developed and maintained code for in-house website primarily using C#','Collaborated with a small team of designers to implement their ideas to the website using Javascript, HTML and css'], company: 'Parkshare', url: 'https://www.parkshare.dk' },
      ];

    return (
        <div id='Experience'>
      <NumberedHeader name='Where I have worked' number={2} />
      <div className='ContainerContent'>
        <div className='ButtonContent'>
        {buttons.map((button, index) => (
          <button className={`SelectButton ${selectedButtonIndex === index ? 'SelectButtons' : 'UnselectedButton'}`}
           key={index} onClick={() => handleClick(button.title, button.timeLine,  button.content, button.company, button.url, index)}>
            {button.company}
          </button>
        ))}
        </div>
        <div className='Info'>
        <h3 className='H3Header'>{title} @ <a className='CompanyHeader' href={url}>{company}</a></h3>
        <p className='TimeLine'>{timeLine}</p>
        <ul className='ListOfResponsibilities'>
                {content.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                </ul>
        </div>
      </div>
    </div>
    );
  }
  
  export default Experience;