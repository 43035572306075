import './numberedHeader.css'

function NumberedHeader(props:{name:string,number:number}) {
    return (
        <div className='NumberedHeader'>
            <h2 className="name" style={{ "--section-number": props.number } as React.CSSProperties}>
                {props.name}
      </h2>
        </div>
    );
  }
  
  export default NumberedHeader;