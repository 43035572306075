import Button from '../ReusableComponents/button';
import NumberedHeader from '../ReusableComponents/numberedHeader';
import './contact.css'

function Contact() {
    return (
        <div id='contact'>
            <NumberedHeader name='What is next?' number={3} />
            <h1>Get In Touch</h1>
            <p className='availability'>I'm available and open to receiving messages in my mailbox, even if I'm not actively pursuing new opportunities at the moment. I'll make an effort to respond to you whether you have a question or simply want to say hello!</p>
            <Button name='Say hello' link='mailto:edvinas.andrijauskas2000@gmail.com' height={60} width={135} marginTop={50}/>
        </div>
    );
  }
  
  export default Contact;