import { Triangle } from "react-loader-spinner";
import './loader.css'
const Loading = () => {
  return (
    <div className="loading">
      <Triangle color="#64ffda" />
    </div>
  );
};

export default Loading;