import React, { useState } from "react";
import "./hamburger.css";

interface HamburgerProps {
  onClick: () => void;
}

function Hamburger({ onClick }: HamburgerProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    onClick();
  };
  return (
    <>
    <button className={`Hamburger ${isOpen && "open"}`} onClick={handleClick}>
      <div className="Hamburger-line" />
      <div className="Hamburger-line" />
      <div className="Hamburger-line" />
    </button>
    </>
  );
}

export default Hamburger;
