import { useState, useEffect } from 'react';
import Button from '../ReusableComponents/button';
import './header.css'
import HamburgerNav from './hamburgerNav';

function Header() {

  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    return (
      <>
        <nav className="nav">
        <div className='logo'><a href='/'><svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 128 128"><title>Logo</title><g transform="translate(-12.000000, -8.000000)"><g transform="translate(11.000000, 11.000000)"><polygon id="Shape" fill="#0A192F" stroke="#64ffda" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" points="64,4 15,41 15,90 64,119 113,90 113,41" /><text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#64ffda" fontSize="80">E</text></g></g></svg></a></div>
        <div className='LinksAndButtonDiv'>
        <ol>
          <li><a href="/#AboutMe">About</a></li>
          <li><a href="/#Experience">Experience</a></li>
          <li><a href="/#contact">Contact</a></li>
          <Button name='Resume' link='/resume.pdf' height={40} width={90} marginTop={0}/>
        </ol>
        </div>
      </nav>
       <HamburgerNav />
    </>
    );
  }
  
  export default Header;