import './rightElement.css'

function RightElement() {
    return (
        <div className="Email">
            <p><a href="mailto:edvinas.andrijauskas2000@gmail.com">edvinas.andrijauskas2000@gmail.com</a></p>
        </div>
    );
  }
  
  export default RightElement;