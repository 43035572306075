
import NumberedHeader from '../ReusableComponents/numberedHeader';
import './aboutMe.css'



function AboutMe() {
    return (
        <div id='AboutMe'>
            <NumberedHeader name='About me' number = {1}/>
            <div className='InformationHolder'>
            <div className='infoAboutMe'>
                 <p>Hello! I'm Edvinas, and I have a passion for creating interactive online experiences. My journey into development began in 2018 when I made the decision to embark on programming lessons. Since then, my interest has only grown, and I've been working hard to improve my abilities.</p>
                 <p>Fast-forward to today, I have had the privilege of gaining experience at both a start-up and a corporation. Currently, my focus revolves around creating diverse solutions, including data retrieval and database organization, within the dynamic environment of Energi Danmark.</p>
                <p>Here are a few technologies I’ve been working with recently:</p>
                <ul className='Technologies'>
                    <li>C#</li>
                    <li>SQL</li>
                    <li>TypeScript</li>
                    <li>React/Angular</li>
                </ul>
                 </div>
            <div className='image'>
                <img src={require('./round2.png')} alt="Edvinas picture" ></img>
                </div>
            </div>
        </div>
    );
  }
  
  export default AboutMe;