import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import Header from './compontents/Header/header';
import LeftElement from './compontents/SideElelemnts/leftElement';
import RightElement from './compontents/SideElelemnts/rightElement';
import './index.css'
import Footer from './compontents/Footer/footer';
import Loading from "./compontents/ReusableComponents/loader";
import ContentContainer from "./compontents/Content/contentContainer";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
 
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <div className={isLoading ? '' : 'fade-in'}>
      {isLoading ? (
        <Loading />
      ) : (
        <React.StrictMode>
          <Header />
          <ContentContainer />
          <LeftElement />
          <RightElement />
          <Footer />
        </React.StrictMode>
      )}
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
