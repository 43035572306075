
import AboutMe from './aboutMe';
import Contact from './contact';
import './contentContainer.css'
import Description from './description';
import Experience from './experience';

function ContentContainer() {
    return (
        <div className='ContentDiv'>
            <Description/>
            <AboutMe/>
            <Experience/>
            <Contact/>
        </div>
    );
  }
  
  export default ContentContainer;