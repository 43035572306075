import './footer.css'

function Footer() {
    return (
        <div className='Footer'>
            <p>Built by Edvinas Andrijauskas</p>
        </div>
    );
  }
  
  export default Footer;