import { useState, useEffect } from 'react';
import Hamburger from './hamburger';
import Button from '../ReusableComponents/button';
import './hambugerNav.css'


function HamburgerNav() {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    return (
        <nav className='HamburgerNav'>
        <div className='hamburgerLogo'><a href='/'><svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 128 128"><title>Logo</title><g transform="translate(-12.000000, -8.000000)"><g transform="translate(11.000000, 11.000000)"><polygon id="Shape" fill="#0A192F" stroke="#64ffda" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" points="64,4 15,41 15,90 64,119 113,90 113,41" /><text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#64ffda" fontSize="80">E</text></g></g></svg></a></div>
        <Hamburger onClick={() => setIsOpen(!isOpen)} />
        <div className={`Menu ${isOpen && "open"}`}>
          <div className='MenuHeader'>
          <div className='hamburgerLogo'><a href='/'><svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 128 128"><title>Logo</title><g transform="translate(-12.000000, -8.000000)"><g transform="translate(11.000000, 11.000000)"><polygon id="Shape" fill="#0A192F" stroke="#64ffda" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" points="64,4 15,41 15,90 64,119 113,90 113,41" /><text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#64ffda" fontSize="80">E</text></g></g></svg></a></div>
          <div className='svgButton'>
                <button onClick={() => setIsOpen(!isOpen)}>
                <svg fill="#64ffda" height="25px" width="25px" version="1.1" id="Capa_1" viewBox="0 0 460.775 460.775">
                <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
	            c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
	            c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
	            c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
	            l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
	            c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                </svg>
            </button>
          </div>
        </div>
            <div className="Menu-items">
                <a href="/#aboutMe" onClick={() => setIsOpen(!isOpen)}>About</a>
                <a href="/#Experience" onClick={() => setIsOpen(!isOpen)} >Experience</a>
                <a href="/#contact" onClick={() => setIsOpen(!isOpen)}>Contact</a>
                <Button name='Resume' link='/resume.pdf' height={40} width={90} marginTop={0}/>
            </div>
         </div>
        </nav>
    );
  }
  
  export default HamburgerNav;