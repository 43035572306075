import './description.css'
import React from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
function Description() {
    return (
        <>
        <div className='DescriptionContainer'>
        <div className='big-heading'>
            <div className='Description'>
            <h1>Hi, my name is</h1>
            </div>
            <div className='Description'>
            <h2>Edvinas Andrijauskas.</h2>
            </div>
            <div className='Description'>
            <h3> I am a Software Engineer.</h3>
            </div>
            <div className='Paragraph'>
            <p className='DescriptionParagraph'>As a current full-stack software developer at <a href='https://www.energidanmark.dk/'>Energi Danmark</a>,
               I am responsible for creating and maintaining software
               solutions that support the company's needs.
            </p>
            </div>
        </div>
        <div className='DescriptionImg'>
        <Player
        autoplay
        loop
        src="https://assets5.lottiefiles.com/packages/lf20_3ntisyac.json"
        style={{ height: '400px', width: '600px' }}
        background="transparent"
        >
        </Player>      
        </div>
        </div>
        </>
    );
  }
  
  export default Description;